import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContactForm from '../../components/ContactForm';
import Layout from '../../components/Layout';
import Hero from '../../components/Hero';
import SEO from '../../components/SEO';

// import styles from './contact-page.module.css';

const ContactPage = ( { data } ) => {
	const {
		file: {
			childMarkdownRemark: {
				frontmatter: {
					hero: {
						image: { childImageSharp: { fluid: hero } },
						alignment
					}
				},
				html: body
			}
		}
	} = data;

	return (
		<>
			<SEO />
			<Layout>
				{	hero
					? <Hero alignment={ alignment } fluid={ hero } />
					: null
				}
				{ body
					? <section>
						<div className="wrapper">
							<div className="body" dangerouslySetInnerHTML={ { __html: body } } />
							<ContactForm />
						</div>
					</section>
					: null
				}
			</Layout>
		</>
	);
};

ContactPage.propTypes = { data: PropTypes.object };

export default ContactPage;

export const query = graphql`
	query ContactPage {
		file(relativePath: {eq: "pages/contact.md"}) {
			childMarkdownRemark {
				frontmatter {
					hero {
						image {
							childImageSharp {
								fluid(maxWidth: 3000, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						alignment
					}
				}
				html
			}
		}
	}
`;
