import React from 'react';

import styles from './contact-form.module.css';

const ContactForm = () => {
	return (
		<form
			autoComplete="off"
			className={ styles.form }
			data-netlify="true"
			method="post"
			name="contact"
		>
			{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
			<input type="hidden" name="form-name" value="contact" />
			<div className={ styles.formContainer }>
				<span className={ styles.inputs }>
					<div className={ styles.field }>
						<label htmlFor="name">name</label>
						<input
							id="name"
							type="text"
							name="name"
						/>
					</div>
					<div className={ styles.field }>
						<label htmlFor="email">email</label>
						<input
							id="email"
							type="email"
							name="email"
						/>
					</div>
					<div className={ styles.field }>
						<label htmlFor="subject">subject</label>
						<input
							id="subject"
							type="text"
							name="subject"
						/>
					</div>
				</span>
				<span className={ styles.message }>
					<div className={ styles.field }>
						<label htmlFor="message">message</label>
						<textarea
							id="message"
							name="message"
							rows="7"
						/>
					</div>
				</span>
			</div>
			<button
				className={ styles.submit }
				type="submit"
			>
				send
			</button>
		</form>
	);
};

export default ContactForm;
