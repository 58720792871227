import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

function SEO( { description, image, lang, meta, title, url } ) {
	const { pathname } = useLocation();
	const { site } = useStaticQuery( graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image {
              url
              alt
						}
						siteUrl
          }
        }
      }
    ` );

	const metaDescription = description || site.siteMetadata.description;
	const metaImage = image || site.siteMetadata.image;
	const metaTitle = title || site.siteMetadata.title;
	const metaUrl = url || site.siteMetadata.siteUrl;

	return (
		<Helmet
			htmlAttributes={ { lang } }
			title={ metaTitle }
			titleTemplate={ `%s | ${ site.siteMetadata.title }` }
			meta={ [
				// open graph
				{
					name: 'description',
					content: metaDescription
				},
				{
					property: 'og:title',
					content: title
				},
				{
					property: 'og:description',
					content: metaDescription
				},
				{
					property: 'og:type',
					content: 'website'
				},
				{
					property: 'og:url',
					content: metaUrl
				},
				{
					property: 'og:image',
					content: metaImage.url
				},
				// twitter card
				{
					name: 'twitter:card',
					content: 'summary'
				},
				{
					name: 'twitter:title',
					content: title
				},
				{
					name: 'twitter:description',
					content: metaDescription
				},
				{
					name: 'twitter:image',
					content: metaImage.url
				},
				{
					name: 'twitter:image:alt',
					content: metaImage.alt
				}
			].concat( meta ) }
		/>
	);
}

SEO.defaultProps = {
	lang: 'en',
	meta: [],
	description: '',
	image: null,
	url: ''
};

SEO.propTypes = {
	description: PropTypes.string,
	image: PropTypes.shape( {
		url: PropTypes.string,
		alt: PropTypes.string
	} ),
	lang: PropTypes.string,
	meta: PropTypes.arrayOf( PropTypes.object ),
	title: PropTypes.string.isRequired,
	url: PropTypes.string
};

export default SEO;
